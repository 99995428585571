import React, { useState, Fragment } from 'react';
import styles from './top-20.module.scss';
import { dataConvertOverview } from '../../utils/overall-convert';
import { top20Overview } from '../../data/data-top20-overview';
import { formatNumberWithDot, findHighestValue } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import ProgressBar from '../common/progressBarNeg/progress-bar-neg.module';
import SelectDropdown from '../common/selectDropdown/select-dropdown';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';

const title1 = "Millionaires (USD 1m+)";
const title2 = "Centi-millionaires (USD 100m+)";
const title3 = "Billionaires (USD 1bn+)";
const title4 = "Millionaire growth % (2013 to 2023)";

const options = [
  { label: title4, value: "centiGrowth" },
  { label: title1, value: "millionaires" },
  { label: title2, value: "centiMillionaires" },
  { label: title3, value: "billionaires" },
];

const Top20 = () => {

  const [sortColumn, setSortColumn] = useState("centiGrowth");
  // const [display, setDisplay] = useState();
  const [countryCount, setCountryCount] = useState(1);
  const [centiMilCount, setCentiMilCount] = useState(1);
  const [milCount, setMilCount] = useState(1);
  const [bilCount, setBilCount] = useState(1);
  const [centiGrowthCount, setCentiGrowthCount] = useState(1);
  const [numOfCitiesShow, setNumOfCitiesShow] = useState(20);
  const [hideList, setHideList] = useState(true);


  const datatop20Overview = () => {
    return dataConvertOverview(top20Overview);
  }

  const maxGrowthValue = findHighestValue(top20Overview, 'centiGrowth');

  const handleOptionSelect = (option) => {
    // setSelectedOption(option);
    setSortColumn(option.value);
    // setDisplay(-1);
  };
  
  const addNewLine = (str, splitW) => {
    const splited = str.split(splitW);
    splited[0] += '\n';
    return splited.join(splitW);
  }

  const handleSort = (columnName) => {
    switch (columnName) {
      case 'country':
        (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
        break;
      case 'millionaires':
        (milCount === 1) ? setMilCount(-1) : setMilCount(1);
        break;
      case 'centiMillionaires':
        (centiMilCount === 1) ? setCentiMilCount(-1) : setCentiMilCount(1);
        break;
      case 'billionaires':
        (bilCount === 1) ? setBilCount(-1) : setBilCount(1);
        break;
      case 'centiGrowth':
        (centiGrowthCount === 1) ? setCentiGrowthCount(-1) : setCentiGrowthCount(1);
        break;
      default:
        break;
    }

    setSortColumn(columnName);
    // setDisplay(-1);

  };

  const reverseSortNum = (columnName, count) => {
    if (count === 1) {
      return datatop20Overview().sort((a, b) => b[columnName] - a[columnName]);
    }
    return datatop20Overview().sort((a, b) => a[columnName] - b[columnName]);
  }

  const handleSortingOptions = (columnName) => {
    switch (columnName) {
      case 'country':
        return (countryCount === 1) ?
          datatop20Overview().sort((a, b) => b[columnName].localeCompare(a[columnName]))
          : datatop20Overview().sort((a, b) => a[columnName].localeCompare(b[columnName]));
      case 'millionaires':
        return reverseSortNum(columnName, milCount);
      case 'centiMillionaires':
        return reverseSortNum(columnName, centiMilCount);
      case 'billionaires':
        return reverseSortNum(columnName, bilCount);
      case 'centiGrowth':
        return reverseSortNum(columnName, centiGrowthCount);
      default:
        break;
    }
  }


  const sortedData = sortColumn
    ? handleSortingOptions(sortColumn)
    : datatop20Overview();

  // const handleMobileRowClick = (index) => {
  //   setDisplay(index);
  // }

  const handleShowHideList = () => {
    setHideList(!hideList);
    setNumOfCitiesShow(numOfCitiesShow === 10 ? `${sortedData.length}` : 10);
  };


  return (
    <>
      {/* <h3 className="mb-5">TOP 20 CITIES FOR MILLIONAIRES</h3> */}
      <div className={`d-md-none ${styles.sortMobileWrapper}`}>
        <div className={styles.sortTitle}>Sort by</div>
        <SelectDropdown options={options} onSelect={handleOptionSelect} />
      </div>
      <table className={`chartTable ${styles.overviewChartTable}`} >
        <thead>
        <tr>
            <th className={`
                            ${sortColumn === 'centiGrowth' ? styles.cityPercentNegColumn : styles.cityColumn}

            `}>City
            </th>
            <th
                className={`text-left ${styles.cellHideMobile} ${styles.countryColumn} ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''}`}
                onClick={() => handleSort("country")}
            >
                <div className={styles.sortColumnTitle}>
                    {(countryCount === 1) ? <IconSort/> : <ReverseIconSort/>}
                    <span className={styles.sortColumnTitleText}>Country</span>
                </div>
            </th>

            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.progressBarColumn}
                              ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              ${sortColumn === 'centiGrowth' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("centiGrowth")}
                // colSpan="2"
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title4, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Millionaire growth %<br />(2013 to 2023)</span> */}
                    {(centiGrowthCount === 1) ? <IconSort/> : <ReverseIconSort/>}
                </div>
            </th>

            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.milColumn}
                              ${sortColumn === 'millionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'millionaires' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("millionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title1, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>{addNewLine(title1, "(")} Millionaires<br />(USD 1m+)</span> */}
                    {(milCount === 1) ? <IconSort/> : <ReverseIconSort/>}
                </div>
            </th>
            <th
                className={
                    `text-right 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.dataColumn}
                              ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'centiMillionaires' ? styles.displayColumnTitleDiv : ''}
              `}
                onClick={() => handleSort("centiMillionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title2, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Centi-millionaires<br />(USD 100m+)</span> */}
                    {(centiMilCount === 1) ? <IconSort/> : <ReverseIconSort/>}
                </div>
            </th>
            <th
                className={
                    `text-right   
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn}  
                              ${styles.dataColumn}
                              ${sortColumn === 'billionaires' ? styles.selectedColumn : ''}
                              ${sortColumn === 'billionaires' ? styles.displayColumnTitleDiv : ''}

              `}
                onClick={() => handleSort("billionaires")}
            >
                <div className={styles.sortColumnTitle}>
                    <span className={styles.sortColumnTitleText}
                          style={{whiteSpace: 'pre-line'}}>{addNewLine(title3, "(")}</span>
                    {/* <span className={styles.sortColumnTitleText}>Billionaires<br />(USD 1bn+)</span> */}
                    {(bilCount === 1) ? <IconSort/> : <ReverseIconSort/>}

                </div>
            </th>
            {/* <th className={`text-right ${styles.cellHideMobile}`}>Investment <br/>migration options</th> */}
        </tr>
        </thead>
          <tbody>
            {sortedData.map((item, index) => (
          // {sortedData.slice(0, numOfCitiesShow).map((item, index) => (
              <Fragment key={index}>
                  <tr className={`${styles.contentRow} ${styles.openedRow}`}>
                      {/* <tr onClick={() => handleMobileRowClick(index)} className={`${styles.contentRow} ${index === display ? styles.openedRow : ''}`}> */}
                      <td>{item.city}</td>
                      <td className={`text-left 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${sortColumn === 'country' ? styles.selectedColumn : ''}
                              `}>
                          <CountryFlag country={item.country}/>
                      </td>

                      {sortColumn !== "centiGrowth" ?
                          <td rowSpan={2} className={`text-right 
                                              ${styles.hiddenTableRow} 
                                              ${styles.sortableColumn} 
                                              ${styles.combinateRowCells} 
                                              ${sortColumn ? styles.selectedColumn : ''} 
                                              ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                            `}>
                              {formatNumberWithDot(item[sortColumn])}
                          </td>
                          : ''
                      }

                      {/*{sortColumn === 'centiGrowth' ?*/}
                      {/*    <td*/}
                      {/*        rowSpan={2}*/}
                      {/*        className={`text-right */}
                      {/*            ${styles.sortableColumn} */}
                      {/*            ${styles.hiddenTableRow} */}
                      {/*            ${sortColumn !== 'centiGrowth' ? styles.cellHideMobile : ''}*/}
                      {/*            ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}*/}
                      {/*            ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}*/}
                      {/*          `}*/}
                      {/*        style={{paddingRight: 0, width: '5%'}}*/}
                      {/*    >*/}
                      {/*        <ProgressBar*/}
                      {/*            percentValue={item.centiGrowth < 0 ? Math.abs(item.centiGrowth) : 0}*/}
                      {/*            labelText={''}*/}
                      {/*            type="left"*/}
                      {/*        />*/}
                      {/*    </td>*/}
                      {/*    : ''*/}
                      {/*}*/}

                      {sortColumn === 'centiGrowth' ?
                          <td
                              rowSpan={2}
                              className={`text-right 
                                  ${styles.sortableColumn} 
                                  ${styles.hiddenTableRow} 
                                  ${sortColumn !== 'centiGrowth' ? styles.cellHideMobile : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                                `}
                              style={{borderRight: 0}}
                              // style={{paddingLeft: 0, borderRight: 0, width: '25%'}}
                          >
                              <ProgressBar
                                  percentValue={item.centiGrowth > 0 ? item.centiGrowth / maxGrowthValue * 100 : 0}
                                  labelText={item.centiGrowth + "%"}
                              />
                          </td>
                          : ''
                      }


                      {/*<td className={`text-right */}
                      {/*          ${styles.cellHideMobile} */}
                      {/*          ${styles.sortableColumn} */}
                      {/*          ${styles.cellOnMobile} */}
                      {/*          ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}*/}
                      {/*          ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}*/}
                      {/*        `}*/}
                      {/*    style={{paddingRight: 0, width: '5%'}}*/}
                      {/*>*/}
                      {/*    <ProgressBar*/}
                      {/*        percentValue={item.centiGrowth < 0 ? Math.abs(item.centiGrowth) : 0}*/}
                      {/*        labelText={''}*/}
                      {/*        type="left"*/}
                      {/*    />*/}
                      {/*</td>*/}
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              `}
                          // style={{width: '25%'}}
                          // style={{paddingLeft: 0, width: '25%'}}
                      >
                          <ProgressBar
                              percentValue={item.centiGrowth > 0 ? item.centiGrowth / maxGrowthValue * 100 : 0}
                              labelText={item.centiGrowth + "%"}
                          />
                      </td>

                      {/* <td className={`text-right ${styles.cellHideMobile}`}>
                                  {item.link && (
                                      <a href={item.link} target="_blank" className={styles.linkText} rel="noreferrer">Find out more</a>
                                  )}
                                  
                              </td> */}
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'millionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.millionaires)}
                      </td>
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.centiMillionaires)}
                      </td>
                      <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'billionaires' ? styles.selectedColumn : ''}
                              `}>
                          {formatNumberWithDot(item.billionaires)}
                      </td>
                  </tr>
                  {/* {index === display && ( */}
                  <tr className={`${styles.hiddenTableRow}`}>
                      <td className={`${styles.countryMobileDiv}`}
                          style={{'borderRight': '1px solid #C1C5C8'}}
                      >
                          <CountryFlag country={item.country}/>
                      </td>
                  </tr>
                  {/* )}  */}

              </Fragment>
          ))}


        </tbody>
      </table>



      <div className="footer-note mt-5">
        <ul>
          <li>Figures for December 2023.</li>
          <li>Only includes individuals living in each city (residents).</li>
          <li>Millionaire figures rounded to nearest 100.</li>
        </ul>
        <p>Source: World's Wealthiest Cities Report 2024</p>
      </div>
    </>
  )
}

export default Top20;