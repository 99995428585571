export const getNumberWithOrdinalWithSuperscript = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return (
    <span>
      {n}
      <sup>{s[(v - 20) % 10] || s[v] || s[0]}</sup>
    </span>
  );
};


export const slugify = (str) => {
  return str
    .toString()                           // Cast to string (optional)
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()                  // Convert the string to lowercase letters
    .trim()                                  // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')            // Replace spaces with -
    .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

export const formatNumberWithDot = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const groupBy = prop => data => {
  return data.reduce((dict, item) => {
    const { [prop]: _, ...rest } = item;
    dict[item[prop]] = [...(dict[item[prop]] || []), rest];
    return dict;
  }, {});
};

export const imageLink = (name) =>{
  const imageUrl = './images/' + slugify(name) + '.jpg';
  // const imageUrl = 'https://via.placeholder.com/400x400.png?text=' + name;
  return imageUrl;
}

export const flag = (name) =>{
  const imageUrl = './flags/' + slugify(name) + '.png';
  return imageUrl;
}

export const findHighestValue = (data, name) => {
  let maxValue = 0;

  data.forEach(item => {
      // Remove the '%' sign and convert to a number
      let targetValue = item[name];

      if (targetValue > maxValue) {
          maxValue = targetValue;
      }
  });

  return maxValue;
}
