import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import Top20 from './components/top-20/top-20';
import App from "./App";
import qs from "qs";

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

function getEls(embedSelector) {
    if (typeof embedSelector === "string") {
        const els = document.querySelectorAll(embedSelector);
        // if (!els) throw new Error(`No divs matching selector "${embedSelector}"`);
        if (!els) return;
        return els;
    } else {
        return embedSelector;
    }
}

function getUrlParams() {
    const params = qs.parse(window.location.search.slice(1));

    return {
        type: params.embed,
        city: params.city,
    };
}

window.addEventListener("DOMContentLoaded", () => {
    const params = getUrlParams();
    
    if (params) {

        // if (getEl("[data-centi-2023]")) {
        //     const container = getEl("[data-centi-2023]");
        //     const root = createRoot(container); // createRoot(container!) if you use TypeScript
        //     // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
        //     root.render(<App {...params} />);
        // }
        
        if (getEl("[data-top20-embed]")) {
          const container = getEl("[data-top20-embed]");
          const root = createRoot(container); // createRoot(container!) if you use TypeScript
          root.render(<Top20 {...params} />);
        }


    }
});


