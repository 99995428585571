export const getNumberWithOrdinalWithSuperscript = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return (
    <span>
      {n}
      <sup>{s[(v - 20) % 10] || s[v] || s[0]}</sup>
    </span>
  );
};


export const slugify = (str) => {
  return str
    .toString()                           // Cast to string (optional)
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()                  // Convert the string to lowercase letters
    .trim()                                  // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')            // Replace spaces with -
    .replace(/[^\w-]+/g, '')     // Remove all non-word chars
    .replace(/--+/g, '-');        // Replace multiple - with single -
}

export const formatNumberWithDot = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const groupBy = prop => data => {
  return data.reduce((dict, item) => {
    const { [prop]: _, ...rest } = item;
    dict[item[prop]] = [...(dict[item[prop]] || []), rest];
    return dict;
  }, {});
};

export const imageLink = (name) =>{
  const imageUrl = './images/' + slugify(name) + '.jpg';
  // const imageUrl = 'https://via.placeholder.com/400x400.png?text=' + name;
  return imageUrl;
}

export const flag = (name) =>{
  const imageUrl = './flags/' + slugify(name) + '.png';
  return imageUrl;
}

export const henleyFlagLink = (iso) =>{
  const imageUrl = 'https://cdn.henleyglobal.com/themes/hgo/public/assets/img/flags/' + slugify(iso) + '.png';
  return imageUrl;
}


export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter({ data, comparator, searchTerm, filterColumn }) {
  const stabilizedData = data.map((el, index) => [el, index]);
  stabilizedData.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return (order !== 0) ? order : ( a[1] - b[1] );
  });
  data = stabilizedData.map((el) => el[0]);

  if (searchTerm) {
    data = data.filter((item) => ( (item[filterColumn].toLowerCase() === searchTerm.toLowerCase()) ));
  }

  return data;
}


export function percentageFixed(input) {
  return (input * 100).toFixed(2);
}

export function rankSuffix(rank) {
  var j = rank % 10,
      k = rank % 100;
  if (j === 1 && k !== 11) {
    return (<span>{rank}<sup>st</sup></span>);
  }
  if (j === 2 && k !== 12) {
    return (<span>{rank}<sup>nd</sup></span>);
  }
  if (j === 3 && k !== 13) {
    return (<span>{rank}<sup>rd</sup></span>);
  }
  return (<span>{rank}<sup>th</sup></span>);
}