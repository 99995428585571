const top20Overview = [
    {
        "city": "Shenzhen",
        "country": "China",
        "millionaires": 50300,
        "centiMillionaires": 154,
        "billionaires": 22,
        "centiGrowth": 140
    },
    {
        "city": "Hangzhou",
        "country": "China",
        "millionaires": 31600,
        "centiMillionaires": 102,
        "billionaires": 12,
        "centiGrowth": 125
    },
    {
        "city": "Bengaluru",
        "country": "India",
        "millionaires": 13200,
        "centiMillionaires": 42,
        "billionaires": 8,
        "centiGrowth": 120
    },
    {
        "city": "Austin",
        "country": "USA",
        "millionaires": 32700,
        "centiMillionaires": 92,
        "billionaires": 10,
        "centiGrowth": 110
    },
    {
        "city": "Guangzhou",
        "country": "China",
        "millionaires": 24500,
        "centiMillionaires": 70,
        "billionaires": 9,
        "centiGrowth": 110
    },
    {
        "city": "Scottsdale",
        "country": "USA",
        "millionaires": 14500,
        "centiMillionaires": 63,
        "billionaires": 5,
        "centiGrowth": 102
    },
    {
        "city": "Ho Chi Minh City",
        "country": "Vietnam",
        "millionaires": 7200,
        "centiMillionaires": 24,
        "billionaires": 3,
        "centiGrowth": 100
    },
    {
        "city": "Delhi",
        "country": "India",
        "millionaires": 30700,
        "centiMillionaires": 123,
        "billionaires": 16,
        "centiGrowth": 95
    },
    {
        "city": "Sharjah",
        "country": "UAE",
        "millionaires": 4100,
        "centiMillionaires": 11,
        "billionaires": " -   ",
        "centiGrowth": 95
    },
    {
        "city": "Grand Baie",
        "country": "Mauritius",
        "millionaires": 900,
        "centiMillionaires": 5,
        "billionaires": " -   ",
        "centiGrowth": 95
    },
    {
        "city": "Palm Beach & West Palm Beach",
        "country": "USA",
        "millionaires": 10200,
        "centiMillionaires": 69,
        "billionaires": 9,
        "centiGrowth": 93
    },
    {
        "city": "Beijing",
        "country": "China",
        "millionaires": 125600,
        "centiMillionaires": 347,
        "billionaires": 42,
        "centiGrowth": 90
    },
    {
        "city": "Kigali",
        "country": "Rwanda",
        "millionaires": 800,
        "centiMillionaires": 3,
        "billionaires": " -   ",
        "centiGrowth": 88
    },
    {
        "city": "Shanghai",
        "country": "China",
        "millionaires": 123400,
        "centiMillionaires": 322,
        "billionaires": 39,
        "centiGrowth": 84
    },
    {
        "city": "Greenwich & Darien",
        "country": "USA",
        "millionaires": 12500,
        "centiMillionaires": 120,
        "billionaires": 10,
        "centiGrowth": 84
    },
    {
        "city": "The Bay Area",
        "country": "USA",
        "millionaires": 305700,
        "centiMillionaires": 675,
        "billionaires": 68,
        "centiGrowth": 82
    },
    {
        "city": "Mumbai",
        "country": "India",
        "millionaires": 58800,
        "centiMillionaires": 236,
        "billionaires": 29,
        "centiGrowth": 82
    },
    {
        "city": "Dubai",
        "country": "UAE",
        "millionaires": 72500,
        "centiMillionaires": 212,
        "billionaires": 15,
        "centiGrowth": 78
    },
    {
        "city": "Miami",
        "country": "USA",
        "millionaires": 35300,
        "centiMillionaires": 164,
        "billionaires": 15,
        "centiGrowth": 78
    },
    {
        "city": "Dallas",
        "country": "USA",
        "millionaires": 68600,
        "centiMillionaires": 125,
        "billionaires": 15,
        "centiGrowth": 75
    }
];

const centiWorldData = [
  // {
  //     "city": "World",
  //     "country": "=",
  //     "millionaires": "23200",
  //     "centiMillionaires": "28420",
  //     "billionaires": "2320",
  //     "centiGrowth": 38
  // }
];

export {top20Overview, centiWorldData};