// import { countryOverview } from "../data/data-country-overview";
import { countryCode } from "../data/data-country-code";


export const dataConvertOverview = (jsonOverview) =>{
    let newObj = jsonOverview.map((item) => ({
      ...item,
      ISO3: countryCode[item.country].ISO3,
      ISO2: countryCode[item.country].ISO2
    }));
    return newObj;
}
  