export const countryCode = {
    "USA":{
        "ISO2":"US",
        "ISO3":"USA"
    },
    "United States":{
        "ISO2":"US",
        "ISO3":"USA"
    },
    "France":{
        "ISO2":"FR",
        "ISO3":"FRA",
    },
    "Portugal":{
        "ISO2":"PT",
        "ISO3":"PRT",
    },
    "Switzerland":{
        "ISO2":"CH",
        "ISO3":"CHE",
    },
    "Italy":{
        "ISO2":"IT",
        "ISO3":"ITA",
    },
    "South Africa":{
        "ISO2":"ZA",
        "ISO3":"ZAF",
    },
    "Japan":{
        "ISO2":"JP",
        "ISO3":"JPN",
    },
    "UK":{
        "ISO2":"GB",
        "ISO3":"GBR",
    },
    "Singapore":{
        "ISO2":"SG",
        "ISO3":"SGP",
    },
    "Hong Kong (SAR China)":{
        "ISO2":"HK",
        "ISO3":"HKG",
    },
    "China":{
        "ISO2":"CN",
        "ISO3":"CHN",
    },
    "Australia":{
        "ISO2":"AU",
        "ISO3":"AUS",
    },
    "Canada":{
        "ISO2":"CA",
        "ISO3":"CAN",
    },
    "Germany":{
        "ISO2":"DE",
        "ISO3":"DEU",
    },
    "South Korea":{
        "ISO2":"KR",
        "ISO3":"KOR",
    },
    "UAE":{
        "ISO2":"AE",
        "ISO3":"ARE",
    },
    "United Arab Emirates":{
        "ISO2":"AE",
        "ISO3":"ARE",
    },
    "India":{
        "ISO2":"IN",
        "ISO3":"IND",
    },
    "Israel":{
        "ISO2":"IL",
        "ISO3":"ISR",
    },
    "Russian Federation":{
        "ISO2":"RU",
        "ISO3":"RUS"
    },
    "Spain":{
        "ISO2":"ES",
        "ISO3":"ESP"
    },
    "New Zealand":{
        "ISO2":"NZ",
        "ISO3":"NZL"
    },
    "Greece":{
        "ISO2":"GR",
        "ISO3":"GRC"
    },
    "Qatar":{
        "ISO2":"QA",
        "ISO3":"QAT",
    },
    "Ireland":{
        "ISO2":"IE",
        "ISO3":"IRL"
    },
    "Saudi Arabia":{
        "ISO2":"SA",
        "ISO3":"SAU"
    },
    "Monaco":{
        "ISO2":"MC",
        "ISO3":"MCO"
    },
    "Türkiye":{
        "ISO2":"TR",
        "ISO3":"TUR"
    },
    "Poland":{
        "ISO2":"PL",
        "ISO3":"POL"
    },
    "Vietnam":{
        "ISO2":"VN",
        "ISO3":"VNM"
    },
    "Egypt":{
        "ISO2":"EG",
        "ISO3":"EGY",
    },
    "Nigeria":{
        "ISO2":"NG",
        "ISO3":"NGA",
    },
    "Hungary":{
        "ISO2":"HU",
        "ISO3":"HUN"
    },
    "Kenya":{
        "ISO2":"KE",
        "ISO3":"KEN",
    },
    "Ghana":{
        "ISO2":"GH",
        "ISO3":"GHA"
    },
    "Angola":{
        "ISO2":"AO",
        "ISO3":"AGO",
    },
    "Tanzania":{
        "ISO2":"TZ",
        "ISO3":"TZA"
    },
    "Namibia":{
        "ISO2":"NA",
        "ISO3":"NAM"
    },
    "Ethiopia":{
        "ISO2":"ET",
        "ISO3":"ETH"
    },
    "Rwanda":{
        "ISO2":"RW",
        "ISO3":"RWA" 
    },
    "Mozambique":{
        "ISO2":"MZ",
        "ISO3":"MOZ"
    },
    "Morocco":{
        "ISO2":"MA",
        "ISO3":"MAR"
    },
    "Zambia":{
        "ISO2":"ZM",
        "ISO3":"ZMB"
    },
    "Netherlands":{
        "ISO2":"NL",
        "ISO3":"NLD"
    },
    "Luxembourg":{
        "ISO2":"LU",
        "ISO3":"LUX"
    },
    "Austria":{
        "ISO2":"AT",
        "ISO3":"AUT"
    },
    "Sweden":{
        "ISO2":"SE",
        "ISO3":"SWE"
    }, 
    "Brazil":{
      "ISO2":"BR",
      "ISO3":"BRA"
    },
    "Taiwan (Chinese Taipei)":{
      "ISO2":"TW",
      "ISO3":"TWN"
    },
    "Swiss":{
      "ISO2":"CH",
      "ISO3":"CHE"
    }, 
    "Taiwan":{
      "ISO2":"TW",
      "ISO3":"TWN"
    },
    "Hong Kong":{
      "ISO2":"HK",
      "ISO3":"HKG",
    },
    "SA":{
      "ISO2":"ZA",
      "ISO3":"ZAF",
    },
    "Mauritius":{
        "ISO2":"MU",
        "ISO3":"MUS",
    },


}
